<template>
  <div class="mt-3">
    <v-skeleton-loader
      v-if="commentsLoading"
      type="list-item-two-line"
    />
    <template v-else>
      <template v-for="(comment, index) of comments">
        <v-list-item
          :key="comment.id"
          :class="comment.isSupport ? 'v-list-item--active blue--text blue lighten-5' : ''"
          data-cy="support-ticket-comment"
        >
          <v-row dense>
            <v-col cols="12">
              <b class="mr-2">{{ comment.userName }}</b>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ comment.createDateTime | formatDateTime }}
                  </span>
                </template>
                <span>{{ comment.createDateTime | formatDateTimeWithTimezone }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <material-support-ticket-attachments
                v-if="Array.isArray(comment.attachments) && comment.attachments.length > 0"
                :attachments="comment.attachments"
                :loading-id="`comment-${comment.id}`"
              />
              <!-- eslint-disable vue/no-v-html -->
              <p
                class="description editable"
                v-html="sanitizeHtml(comment.text)"
              />
              <!-- eslint-enable vue/no-v-html -->
            </v-col>
          </v-row>
        </v-list-item>
        <v-divider
          v-if="index < comments.length - 1"
          :key="index"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DOMPurify from 'dompurify'

export default {
  computed: {
    ...mapGetters('support', { commentsGetter: 'comments' }),
    ...mapState('support', ['clients', 'commentsLoading']),
    clientId () {
      return this.$route.params.clientId
    },
    ticketId () {
      return this.$route.params.ticketId
    },
    comments () {
      return this.commentsGetter(this.clientId, this.ticketId)
    }
  },
  methods: {
    sanitizeHtml (html) {
      return DOMPurify.sanitize(html)
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-expansion-panel-header__icon {
    flex: 1 1 100%;
  }

  ::v-deep .supportComment__attachment {
    padding-bottom: $spacer * 2;
  }

  ::v-deep .supportComment__attachmentHeader,
  ::v-deep .v-expansion-panel--active>.v-expansion-panel-header {
    padding: 0;
    min-height: auto;
  }

  ::v-deep .v-expansion-panel {
    background-color: inherit!important;
    color: inherit!important;

    .v-icon {
      color: inherit!important;
    }
  }
</style>
