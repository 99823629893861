import { render, staticRenderFns } from "./Comments.vue?vue&type=template&id=381ea138&scoped=true&"
import script from "./Comments.vue?vue&type=script&lang=js&"
export * from "./Comments.vue?vue&type=script&lang=js&"
import style0 from "./Comments.vue?vue&type=style&index=0&id=381ea138&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "381ea138",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VDivider,VListItem,VRow,VSkeletonLoader,VTooltip})
